<template>
    <div class="common_content history_box" id="certify">
       <swiper class="swiper" :options="swiperOption">
    <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
    <swiper-slide class="custom-slide">
      <!-- <img alt="" class="bg-img" :src="$cndHost+'pc/assets/index/banner_1.png'"> -->
      <img alt="" class="bg-img" src="../../assets/history/new_bg11.png">
      <!-- <div class="title" data-swiper-parallax="-100">Slide 1</div>
      <div class="subtitle" data-swiper-parallax="-240">Subtitle</div>
      <div class="text" data-swiper-parallax="-360">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor. Suspendisse in sem justo. Integer laoreet magna nec elit suscipit, ac laoreet nibh euismod. Aliquam hendrerit lorem at elit facilisis rutrum. Ut at ullamcorper velit. Nulla ligula nisi, imperdiet ut lacinia nec, tincidunt ut libero. Aenean feugiat non eros quis feugiat.</p> -->
      <!-- </div> -->
    </swiper-slide>
    <swiper-slide class="custom-slide">
      <!-- <img alt="" class="bg-img" :src="$cndHost+'pc/assets/index/banner_2.png'"> -->
        <img alt="" class="bg-img" src="../../assets/history/new_bg22.png">
      <!-- <div class="title" data-swiper-parallax="-100">Slide 2</div> -->
    </swiper-slide>
    <!-- <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div> -->
    
    <!-- <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
    <div class="swiper-button-next swiper-button-white" slot="button-next"></div> -->
  </swiper>
  <div class="swiper-pagination"></div>
    </div>
</template>
<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
export default {
    components: {
      Swiper,
      SwiperSlide
    },
    data() {
        return {
            swiperOption: {
                speed: 600,
                autoplay:true,
                parallax: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
        }
    },
    created() {
     
    },
    mounted() {
       document.querySelector('body').setAttribute('style', 'overflow:hidden;')
    },
    //创建前设置 body样式 隐藏滚动条
    beforeCreate () {
          
    },
    //销毁前清除
    beforeDestroy () {
          document.querySelector('body').removeAttribute('style')
    },
    methods:{

    }
}
</script>
<style scoped>
.custom-slide img{
    height: 100vh;
}
.history_box{
        position: relative;
    left: 294px;
}

#certify >>> .swiper-pagination {
	width: 85%;
	bottom: 20px;
}

#certify >>> .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 5px;
	border: 2px solid #f0f1f3;
	background-color: #f0f1f3;
	width: 20px;
	height: 1px;
	opacity: 1;
	border-radius: 2px;
}

#certify >>> .swiper-pagination-bullets .swiper-pagination-bullet-active {
    border: 2px solid #ee660e;
    background-color: #ee660e;
    border-radius: 2px;
    width: 20px;
    height: 1px;
}
@media screen and (max-width:1500px){
    .custom-slide img{
        height: 85vh;
    }
    .history_box{
        position: relative;
        left: 294px;
        top: 8vh;
    }
}
.swiper-slide{
  height: 100% !important;
}
</style>